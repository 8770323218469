/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import type { NavBarEntry } from 'embercom/components/common-primary-nav-bar';

interface Signature {
  Element: HTMLAnchorElement;
  Args: {
    product: NavBarEntry;
    workspaceID: string;
    onMouseEnter: (productId: string) => void;
    onMouseLeave: () => void;
    productIsActiveRoute: boolean;
  };
  Blocks: {
    default: [];
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class PrimaryNavLink extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PrimaryNav::Link': typeof PrimaryNavLink;
    'primary-nav/link': typeof PrimaryNavLink;
  }
}
