/* import __COLOCATED_TEMPLATE__ from './guide-library-checklist-popover.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    productName: string;
    logoAnalyticsMetadata?: Record<string, any>;
    animationDuration?: number;
    trackHoverObject?: string;
    classNames?: string;
    shouldHideToolTip?: boolean;
    overrideWithRestrictedBanner?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class GuideLibraryChecklistPopover extends Component<Signature> {
  @service declare guideLibraryService: GuideLibraryService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PrimaryNav::GuideLibraryChecklistPopover': typeof GuideLibraryChecklistPopover;
  }
}
